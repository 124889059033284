import React from "react"
import Layout from "../components/layout"

import Sezione from "../components/sezione"
import SEO from "../components/seo"
import CardPagina from "../components/cardPagina"
import BanneronePage from "../components/banneronePage"
import TitoloPagina from "../components/titoloPagina"
import BottoneBianco from "../components/bottoneBianco"
import { Link } from "gatsby"

import {
  Container,
  Row,
  Col,
  Card,
  Badge,
  Tab,
  ListGroup,
  Tabs,
} from "react-bootstrap"

const Termografia = () => (
  <Layout isPagina={true}>
    <SEO
      title="Termografia a infrarossi per muffe, infiltrazioni, umidità di risalita, perdite Lazio: Roma, Pomezia, Aprilia, Latina, Anzio, Nettuno"
      description="Verifica dispersioni, muffe, infiltrazioni, perdite, macchie umido, umidità di risalita, perizia termografica lazio, operatore termografico lazio, termografia a infrarossi, ricerca infiltrazioni Lazio, dispersioni Lazio"
    />
    <BanneronePage title="Termografia" />
    <Container className="mt-4 mb-4">
      <TitoloPagina title="Termografia a infrarossi per muffe, infiltrazioni, umidità di risalita, perdite Lazio: Roma, Pomezia, Aprilia, Latina, Anzio, Nettuno" />
      <Row>
        <Col className="text-center">
          <img
            className="img-fluid mt-3 mx-auto rounded"
            src="../termografia/termografia-anzio-nettuno.png"
            alt="Termografia Anzio Nettuno"
          />
        </Col>
      </Row>
      <CardPagina variante="bordoLeft bordoRight">
        <b>Check-up e diagnosi energetica</b>
        <p>
          Il check-up e la diagnosi di NSE srl hanno come obiettivo quello di
          verificare in che modo l’energia viene utilizzata, quali sono le cause
          degli eventuali sprechi ed eventualmente quali interventi possono
          essere suggeriti all’utente, ossia un piano energetico che valuti non
          solo la fattibilità tecnica ma anche e soprattutto quella economica
          delle azioni correttive proposte.
        </p>
        <b>Obiettivi e finalità della Diagnosi Energetica degli edifici</b>
        <p>La Diagnosi Energetica degli edifici:</p>
        <ul>
          <li>
            è un insieme sistematico di rilievo, raccolta ed analisi dei
            parametri relativi ai consumi specifici e alle condizioni di
            esercizio dell’edificio e dai suoi impianti;{" "}
          </li>
          <li>è una valutazione tecnico-economica dei flussi di energia.</li>
        </ul>
        <p>I suoi obiettivi sono quelli di:</p>
        <ul>
          <li>
            definire il bilancio energetico dell’edificio, individuare gli
            interventi di riqualificazione tecnologica e valutare per ciascun
            intervento le opportunità tecniche ed economiche;{" "}
          </li>
          <li>
            migliorare le condizioni di comfort e di sicurezza, riducendo le
            spese di gestione.
          </li>
        </ul>
        <p>
          Mediante l'impiego di diversi pacchetti software, che elaborano su
          base oraria i carichi termici dell’edificio, i consumi energetici
          degli impianti nonché le temperature di ciascun locale, si riesce a:
        </p>
        <ul>
          <li>
            individuare gli interventi di ottimizzazione e di risparmio
            energetico, valutando l’economicità degli interventi a privati ed
            enti pubblici;{" "}
          </li>
          <li>
            offrire supporto tecnico a privati, enti pubblici e società
            immobiliari fornendo supporto per la progettazione esecutiva;
          </li>
          <li>
            eseguire diagnosi di edifici ad uso residenziale e terziario ed
            edifici pubblici, rilevandone le caratteristiche architettoniche,
            costruttive e impiantistiche ed elaborando dati con programmi di
            calcolo e identificando possibili interventi di riqualificazione
            energetica.
          </li>
        </ul>
      </CardPagina>
      <CardPagina variante="gridParity text-center">
        Le nostre analisi termografiche e relative perizie sono svolte
        esclusivamente da ingegneri energetici-ambientali specializzati nel
        settore di competenza quali{" "}
        <b>operatori termografici certificati di II livello UNI EN ISO 9712</b>,
        in grado di operare nel settore dei controlli non distruttivi: campo
        edilizio, meccanico, elettrotecnico, elettronico, nautico ed
        industriale.
      </CardPagina>
      <Sezione>
        <CardPagina>
          <em>
            <p>
              La Termografia è una tecnica di indagine non distruttiva grazie
              alla quale è possibile visualizzare, sotto-forma di mappa a
              colori, l’energia termica emessa da un corpo, il termogramma.
              Grazie a speciali telecamere digitali ad infrarossi (detta appunto
              termocamere) dotate di un particolare sensore, viene rilevata
              l’energia (termica) nel campo dell’infrarosso proveniente dalle
              superfici. Tale tecnica di analisi viene applicata da
              NonsoloEnergia srl per le seguenti principali finalità in tutto il
              territorio della Regione Lazio:
            </p>
            <ul>
              <li>
                Verifica difetti di isolamento termico, dispersioni termiche e
                ponti termici.
              </li>
              <li>
                Misura e verifica della distribuzione della temperatura
                superficiale nei componenti edilizi (superfici di pavimento,
                pareti, soffitti, ecc.).
              </li>
              <li>
                Verifica della distribuzione superficiale dell'umidità in
                strutture / edifici / componenti nell'ambito di interventi di
                ristrutturazione e/o risanamento di edifici, murature,
                pavimenti, o parti di essi.
              </li>
              <li>
                Individuazione di anomalie e difetti di posa dei sistemi di
                impermeabilizzazione (ad es. guaine), infiltrazioni, muffe,
                perdite, macchie di acqua al soffitto, umidità di risalita,
                condensa, e localizzazione di eventuali (o reali) distacchi
                superficiali di intonaco spesso causa di pericolose
                infiltrazioni d'acqua.
              </li>
              <li>
                Individuazione perdite idrauliche in impianti di riscaldamento,
                sanitari, antincendio.
              </li>
              <li>
                Verifica efficenza moduli fotovoltaici, inverter, quadri e cavi
                elettrici.
              </li>
              <li>
                Analisi efficienza motori e macchine elettriche ed i relativi
                sottocomponenti.
              </li>
            </ul>
            <p>
              NonsoloEnergia srl dispone di un set completo di termocamere per
              le diverse tipologie d'impiego, con risoluzioni in grado di
              individuare con precisione, non solo eventuali problemi di
              isolamento termico, ma anche di effettuare una valutazione
              accurata del corretto funzionamento di un impianto fotovoltaico
              mediante l'individuazione dei cosidetti <em>Hot Spot</em>.
            </p>
          </em>
          <strong className="text-center">
            OPERATORI TERMOGRAFICI CERTIFICATI DI II LIVELLO UNI EN ISO 9712 per
            l'analisi dei dati ed il rilascio di perizie termografiche di valore
            legale e assicurativo.
          </strong>
          <Row>
            <Col sm={6}>
              <img
                className="rounded mt-1 img-fluid "
                src="../termografia/termografia-ad-infrarossi-Roma.jpg"
                alt="Termografia ad Infrarossi Roma"
              />
            </Col>
            <Col sm={6}>
              <img
                className="rounded mt-1 img-fluid "
                src="../termografia/termografia-per-verifica-isolamento-solaio.jpg"
                alt="Termografia per verifica isolamento solaio"
              />
            </Col>
            <Col sm={6}>
              <img
                className="rounded mt-1 img-fluid "
                src="../termografia/termografia-per-muffa.jpg"
                alt="Termografia per muffa"
              />
            </Col>
            <Col sm={6}>
              <img
                className="rounded mt-1 img-fluid "
                src="../termografia/termografia-per-infiltrazioni-acqua.jpg"
                alt="Termografia per infiltrazioni acqua"
              />
            </Col>
            <Col sm={6}>
              <img
                className="rounded mt-1 img-fluid "
                src="../termografia/termografia-su-fotovoltaico.jpg"
                alt="Termografia su fotovoltaico"
              />
            </Col>
            <Col sm={6}>
              <img
                className="rounded mt-1 img-fluid "
                src="../termografia/manutenzione-impianti-fotovoltaici-con-termografia.jpg"
                alt="Manutenzione impianti fotovoltaici con termografia"
              />
            </Col>
          </Row>
          <br />
        </CardPagina>
      </Sezione>
      <CardPagina variante="white bordoTop mt-5">
        <Row>
          <Col xs={12} className="mt-3">
            <p className="titoloGrande text-center gridParity">
              CONTATTACI PER UN'INDAGINE TERMOGRAFICA PREVENTIVI e CONSULENZE
              GRATUITE
              <br />
              Regione Lazio: Roma, Latina, Pomezia, Aprilia, Anzio, Nettuno{" "}
            </p>
          </Col>
          <Col xs={12}>
            <BottoneBianco
              className="mt-3 mx-auto text-center variantePage"
              title="Contattaci"
              link="richiesta-informazioni"
            />
          </Col>
        </Row>
      </CardPagina>
    </Container>
  </Layout>
)

export default Termografia
